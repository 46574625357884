









import { walletStore } from '@/stores/wallet-store'
import { Observer } from 'mobx-vue'
import { Component, Prop, Vue } from 'vue-property-decorator'
import { accountSettingController } from './account-setting-controller';
import { walletSettingController } from './wallet-setting-controller';

@Observer
@Component
export default class ConnectMetamask extends Vue {
  @Prop({ default: true }) requireWallet!: boolean;
  @Prop({ default: true }) requireLogin!: boolean;

  wallet = walletStore

  maskClicked() {
    if (!walletStore.jwt) {
      this.$router.push('/login')
    } else if (!walletStore.isRegisted) {
      if (walletStore.account) {
        accountSettingController.setOpenDialog(true)
      } else {
        walletSettingController.setOpenDialog(true)
      }
    }
  }
}
